<template>
	<div>
		<q-tabs
			inline-label
			active-color="primary"
			indicator-color="primary"
			align="justify"
			narrow-indicator
		>
			<q-route-tab
				v-for='tab in tabs'
				:key="tab.name"
				:name='tab.path'
				:label="tab.name"
				:alert="hasError(tab.name) ? 'negative' : false"
				alert-icon="priority_high"
				:to="tab.path"
			/>
		</q-tabs>
			<router-view></router-view>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "MessageFormTabs",
	props: {
		tabs: Array,
	},
	computed: {
		...mapGetters([ 'tabs_with_errors' ]),
	},
	methods: {
		hasError(tab_name) {
			return this.tabs_with_errors.includes(tab_name);
		},
	},
};
</script>