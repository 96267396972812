<template>
	<div v-if="active_tab_name !== 'Message Finish' && $route.name !== 'Edit Message Finish'" class="field-fab-wrapper">
		<div>
			<q-btn
				class="nav-button"
				flat
				color="primary"
				label="Cancel"
				icon="close"
				:to="{ name: 'Dashboard' }"
				@click="set_active_item(1)"
			/>
		</div>
		<div v-if="!$route.params.id">
			<q-btn
				v-if="active_tab_name !== 'Message Settings'"
				class="nav-button"
				flat
				color="primary"
				label="Previous"
				icon="navigate_before"
				@click="previousTab"
			/>
			<q-btn
				class="nav-button"
				flat
				color="primary"
				label="Next"
				icon-right="navigate_next"
				navigate_next
				@click="nextTab"
			/>
		</div>
		<div
			v-if="$route.params.id">
			<q-btn
				class="nav-button"
				flat
				color="primary"
				label="Finish"
				icon-right="navigate_next"
				navigate_next
				@click="finishTab"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'NavigatingButtons',
	props: {
		active_tab_name: String,
	},
	methods: {
		...mapActions(['set_active_item']),
		nextTab(){
			window.scrollTo(0, 0)
			this.$emit('next-tab');
		},
		previousTab(){
			window.scrollTo(0, 0)
			this.$emit('previous-tab');
		},
		finishTab(){
			window.scrollTo(0, 0)
			this.$emit('finish-tab');
		},
	}
}
</script>
<style>
.nav-button .q-focus-helper {
  display: none;
}
</style>
<style lang="scss" scoped>
	.field-fab-wrapper {
		position: sticky;
		bottom: 0px;
		z-index: 5;
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 100%;
		background: #fff;
		padding: 1.5rem 3rem;
		@media (max-width: 768px) {
			padding: 1rem 0.5rem;
		}
	}
</style>