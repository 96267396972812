<template>
	<div>
		<SuccessErrorMessage :message="message" />
		<div class="tabs-wrapper">
			<MessageFormTabs
				:tabs="tabs"
			/>
			<NavigatingButtons
				:active_tab_name="active_tab_name"
				@finish-tab="finishTab"
			/>
		</div>
	</div>
</template>

<script>
import MessageFormTabs from '@/components/message_forms/MessageFormTabs.vue';
import { mapGetters, mapActions } from 'vuex';
import NavigatingButtons from '@/components/message_forms/NavigatingButtons.vue';
import MessageFormAPI from '@/services/api/MessageForm.js';
import message_forms_utils from '@/utils/message_forms.js';
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";

export default {
	name: 'EditMessage',
	meta: {
		title: 'Alert Cascade - Edit Message'
	},
	components: {
		MessageFormTabs,
		NavigatingButtons,
		SuccessErrorMessage
	},
	data() {
		return {
			customer_group_settings: {},
			show_noticeboard: false,
			available_languages: [],
			active_tab_name: '',
		};
	},
	computed: {
		...mapGetters([
			'current_CG',
			'scenario_type',
			'use_conference_recall',
			'tts_voice',
			'customer_group_ids',
			'descendant_user_ids',
			'logged_user',
			'devices',
			'scenario',
			'use_default_answering_services_content',
			'message_text',
			'people',
			'is_session_init_running',
			'message',
			'recipient_types',
			'removed_media_library_files',
		]),
		tabs: function () {
			let tabs = [];
			if (this.logged_user.customer_permission != 'SENDER') {
				tabs = tabs.concat(
					{"name": "Settings", "path": "settings" },
					{"name": "Devices", "path": "devices" },
				);
				if (this.scenario_type === 'sequence') {
					tabs.splice(3, 0, {"name": "Sequence", "path": "sequence" });
				}
			}
			if (this.logged_user.customer_permission != 'SENDER' || (this.logged_user.customer_permission == 'SENDER' && this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit recipients'))) {
				tabs.splice(1, 0, {"name": "Recipients", "path": "recipients" });
			}
			if (this.logged_user.customer_permission != 'SENDER' || (this.logged_user.customer_permission == 'SENDER' && this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content'))) {
				tabs.splice(4, 0, {"name": "Content", "path": "content" });
			}
			if ((this.logged_user.customer_permission != 'SENDER' || (this.logged_user.customer_permission == 'SENDER' && this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content'))) && this.show_noticeboard) {
				tabs = tabs.concat({"name": "Noticeboard", "path": "noticeboard" });
			}
			if ((this.logged_user.customer_permission == 'ADMIN' || this.logged_user.customer_permission == 'OPERATOR') && this.current_CG.root_customer_group_permissions.includes('Trigger Message')) {
				tabs = tabs.concat({"name": "Trigger", "path": "trigger" });
			}
			tabs = tabs.concat({"name": "Finish", "path": "finish" });
			return tabs;
		}
	},
	methods: {
		...mapActions([
			'setRecipientTypesAndDevices',
			'getTranslations',
			'updateInvocationMethodIsDashboard',
			'updateInvocationMethodIsPhoneInvoke',
			'updateInvocationMethodIsIncidentManager',
			'updateInvocationMethodIsEmailInvoke',
			'updateScenarioTest',
			'updateScenarioType',
			'updateUseConferenceRecall',
			'updateScenarioName',
			'updateScenarioMessageId',
			'updateSelectedLanguage',
			'updateIsSingleCriteria',
			'updateIncludedRecipients',
			'updateIsMultipleCriteriaAll',
			'updateIsExcludeRecipients',
			'updateIsExcludeRecipientsAll',
			'updateExcludedRecipients',
			'updateMessagesContent',
			'updateGlobalMessage',
			'updatePollingValue',
			'updatePollingOptions',
			'updateSelectedDigitOption',
			'updatePollingTimeout',
			'updateSMSTwoWayLink',
			'updateAutoRetry',
			'updateAutoRetryMinutes',
			'updateIncludeLinksInSMS',
			'updateIncludeLinksInEmail',
			'updateEmailSMSAttachmentFiles',
			'updateUseNoticeboard',
			'updateNoticeboardNumber',
			'updateNoticeboardMessage',
			'updateNoticeboardLanguage',
			'updateDefaultNoticeboardMessageVoice',
			'updateDevices',
			'updateOneDeviceState',
			'updateEmailSubjectLine',
			'updateAllDevicesState',
			'updateDispatchContentValue',
			'updateUseDefaultAnsweringServicesContent',
			'updateAnsweringMachineMessageText',
			'updateTTSVoiceSelected',
			'updateUseAccountsDefaultTTSVoice',
			'setSMSAttachments',
			'setEmailAttachments',
			'updateSequenceTime',
			'updateCongerenceBridgeIntro',
			'updateAMDConference',
			'resetFields',
			'updateMessage',
			'updatePeopleList',
			'updateEmailSMSAttachments',
			'updateIncludeRecipientOptions',
			'updateIncludeRecipientOptionValue',
			'updateTriggerScenario',
			'updateTriggerTime',
			'updateTriggeredScenario',
			'updateRemovedMediaLibraryFiles',
			'updateSendQuickReport',
			'updateQuickReportRecipientsType',
			'updateQuickReportRecipients',
			'updateIsSubsetScenario',
			'updateSelectedDevices',
			'showEmailSubject',
			'updateUseMicrosoftTeams',
			'updateUseSlack',
			'updateUsePWA',
			'updateSelectedTeamsWebhooks',
			'updateSelectedSlackWebhooks',
			'updateMessageContentTeams',
			'updateMessageContentSlack',
			'updateIncludeLinksInTeams',
			'updateIncludeLinksInSlack',
			'setTeamsAttachments',
			'setSlackAttachments'
		]),
		finishTab() {
			this.active_tab_name = 'Edit Message Finish';
			this.$router.push({name: 'Edit Message Finish', params: {id: this.$route.params.id }})
		},
		checkSavedLanguage(language) {
			let found = false;
			for (let lan in this.available_languages) {
				if (this.available_languages[lan] == language) {
					found = true;
				}
			}
			return found;
		},
		setInvocationMethodSliders(flags) {
			if (flags) {
				this.updateInvocationMethodIsDashboard(flags.is_dashboard);
				this.updateInvocationMethodIsPhoneInvoke(flags.is_phone_invoke);
				this.updateInvocationMethodIsIncidentManager(flags.is_incident_manager);
				this.updateInvocationMethodIsEmailInvoke(flags.is_email_invoke);
			}
		},
		setScenarioTypeNameId(response) {
			this.updateScenarioTest(!response.real);
			this.updateScenarioType(response.scenario_type);
			this.updateScenarioName(response.name);
			this.updateScenarioMessageId(response.message_id);
		},
		setSystemPromptLanugage(scenario_settings) {
			let selected_language_is_available = this.checkSavedLanguage(scenario_settings.selected_language);
			let selected_language = "";
			if (selected_language_is_available) {
				selected_language = scenario_settings.selected_language;
			} else if (this.tts_voice.selected != undefined) {
				selected_language = this.tts_voice.selected.language;
			}
			else if (this.customer_group_settings['default_tts_voice'] != "" && this.customer_group_settings['default_tts_voice'] != undefined) {
				selected_language = this.customer_group_settings['default_tts_voice']['language'];
			} else {
				selected_language = message_forms_utils.default_people()['language'];
			}
			this.updateSelectedLanguage(selected_language);
		},
		setDevicesAndRecipients(scenario_settings) {
			this.updateAllDevicesState({ "state": false, "devices_len": this.devices.length });
			this.updateDevices(scenario_settings.devices);
			let new_devices = []
			for (let item in this.devices) {
				for (let item1 in this.scenario.devices) {
					if (this.scenario.devices[item1].id === this.devices[item].id &&
						this.scenario.devices[item1].dispatch_type === this.devices[item].dispatch_type
					) {
						new_devices.push(this.scenario.devices[item1])
					}
					if (this.scenario.devices[item1].dispatch_type === this.devices[item].dispatch_type && this.scenario.devices[item1].field_id === this.devices[item].field_id) {
						this.updateOneDeviceState({ "index": item, "state": true });
					}
				}
			}
			this.updateDevices(new_devices);

			this.updateSelectedDevices();
			this.showEmailSubject();

			// we are receiving the recipients from the settings stored in the database
			// however the objects that need to be shown in the recipients values are not in the same format, i.e. we only have the id
			// probably needs better logic
			for (let recipient of scenario_settings.recipients.include_criteria) {
				let recipient_value = [];
				if (recipient.recipient_type && recipient.recipient_type.type === 'group') {
					recipient['recipient_type']['options'] = this.customer_group_ids;
					for (let item in recipient.recipient_value) {
						for (let object in this.customer_group_ids) {
							if (recipient.recipient_value[item] == this.customer_group_ids[object]['id']) {
								recipient_value.push(this.customer_group_ids[object]);
							}
						}
					}
					recipient['recipient_value'] = recipient_value;
				}
				if (recipient.recipient_type && recipient.recipient_type.type === 'user') {
					recipient['recipient_type']['options'] = this.descendant_user_ids;
					for (let item in recipient.recipient_value) {
						for (let object in this.descendant_user_ids) {
							if (recipient.recipient_value[item] == this.descendant_user_ids[object]['id']) {
								recipient_value.push(this.descendant_user_ids[object]);
							}
						}
					}
					recipient['recipient_value'] = recipient_value;
				}

				if (recipient.recipient_type && recipient.recipient_type.type === 'field') {
					for (let item in recipient.recipient_value) {
						for (let object in this.logged_user.descendant_fields) {
							for (let object1 in this.logged_user.descendant_fields[object].options) {
								if (recipient.recipient_value[item] == this.logged_user.descendant_fields[object].options[object1]['id']) {
									recipient['recipient_type']['options'] = this.logged_user.descendant_fields[object].options;
									recipient_value.push(this.logged_user.descendant_fields[object].options[object1]);
								}
							}
						}
					}
					if (recipient['recipient_type']['options'] == undefined) {
						recipient['recipient_type']['options'] = [];
					}
					recipient['recipient_value'] = recipient_value;
				}
			}

			if (scenario_settings.recipients.exclude_criteria.length >= 1 &&
				!!Object.keys(scenario_settings.recipients.exclude_criteria[0]).length) {

				for (let recipient of scenario_settings.recipients.exclude_criteria) {
					let recipient_value = [];
					if (recipient.recipient_type.type === 'group') {
						recipient['recipient_type']['options'] = this.customer_group_ids;
						for (let item in recipient.recipient_value) {
							for (let object in this.customer_group_ids) {
								if (recipient.recipient_value[item] == this.customer_group_ids[object]['id']) {
									recipient_value.push(this.customer_group_ids[object]);
								}
							}
						}
						recipient['recipient_value'] = recipient_value;
					}
					if (recipient.recipient_type.type === 'user') {
						recipient['recipient_type']['options'] = this.descendant_user_ids;
						for (let item in recipient.recipient_value) {
							for (let object in this.descendant_user_ids) {
								if (recipient.recipient_value[item] == this.descendant_user_ids[object]['id']) {
									recipient_value.push(this.descendant_user_ids[object]);
								}
							}
						}
						recipient['recipient_value'] = recipient_value;
					}
					if (recipient.recipient_type.type === 'field') {
						for (let item in recipient.recipient_value) {
							for (let object in this.logged_user.descendant_fields) {
								for (let object1 in this.logged_user.descendant_fields[object].options) {
									if (recipient.recipient_value[item] == this.logged_user.descendant_fields[object].options[object1]['id']) {
										recipient['recipient_type']['options'] = this.logged_user.descendant_fields[object].options;
										recipient_value.push(this.logged_user.descendant_fields[object].options[object1]);
									}
								}
							}
						}
						recipient['recipient_value'] = recipient_value;
					}
				}
			}
			if (scenario_settings.EMAIL) {
				this.updateEmailSubjectLine(scenario_settings.EMAIL.email_subject_line);
			}
		},
		setRecipientCriteriaSliders(scenario_settings) {
			this.updateIsSingleCriteria(scenario_settings.recipients.include_criteria.length == 1);
			if (!this.$store.getters.is_single_criteria) {
				this.updateIsMultipleCriteriaAll(scenario_settings.recipients.include_operator === 'AND');
			}
			if (!window.location.search){
				this.updateIncludedRecipients(scenario_settings.recipients.include_criteria);
			}

			this.updateIsExcludeRecipients(scenario_settings.recipients.exclude_criteria.length >= 1 &&
				!!Object.keys(scenario_settings.recipients.exclude_criteria[0]).length);

			if (this.$store.getters.is_exclude_recipients) {
				this.updateIsExcludeRecipientsAll(scenario_settings.recipients.exclude_operator === 'AND');
			}
			if (!window.location.search){
				this.updateExcludedRecipients(scenario_settings.recipients.exclude_criteria);
			}
		},
		setConferenceSettings(scenario_settings) {
			if (this.scenario_type === 'conference') {
				this.updateCongerenceBridgeIntro(scenario_settings.bridge_intro_live === 'default_conference_live');
				this.updateAMDConference(scenario_settings.bridge_intro_amd === 'default_conference_amd');
				this.updateUseConferenceRecall(scenario_settings.recall_participants)
				if (scenario_settings.VOICE) {
					this.updateGlobalMessage(scenario_settings.VOICE.message);
					this.updateAnsweringMachineMessageText(scenario_settings.VOICE.message_amd);
				}
				for (let item in this.people) {
					if (this.people[item]['name'] === scenario_settings.VOICE.tts_voice) {
						this.updateTTSVoiceSelected(this.people[item]);
						break;
					}
				}
			}
		},
		setPollingOptionsSettings(scenario_settings) {
			if (scenario_settings.polling_options != undefined && scenario_settings.polling_options != null) {
				this.updatePollingValue(true);
				this.updatePollingOptions(scenario_settings.polling_options);
				for (let item in scenario_settings.polling_options) {
					this.updateSelectedDigitOption({ "index": item, "label": scenario_settings.polling_options[item].option_digit });
				}
				this.updatePollingTimeout(scenario_settings.polling_timeout);
				this.updateSMSTwoWayLink(scenario_settings.sms_two_way_link);
			}
		},
		async setSmsEmailAttachments(scenario_settings) {
			if (this.$store.getters.messages_content) {
				if (scenario_settings.EMAIL) {
					let has_email_attachments = scenario_settings.EMAIL.email_attachments &&
						scenario_settings.EMAIL.email_attachments.length > 0 ? true : false
					this.updateIncludeLinksInEmail(has_email_attachments);
				}
				if (scenario_settings.SMS) {
					let has_sms_attachments = scenario_settings.SMS && scenario_settings.SMS.sms_attachment_files &&
						scenario_settings.SMS.sms_attachment_files.length > 0 ? true : false
					this.updateIncludeLinksInSMS(has_sms_attachments);
				}
				if (scenario_settings.microsoft_teams) {
					this.updateIncludeLinksInTeams(scenario_settings.microsoft_teams.attachments && scenario_settings.microsoft_teams.attachments.length > 0);
				}
				if (scenario_settings.slack){
					this.updateIncludeLinksInSlack(scenario_settings.slack.attachments && scenario_settings.slack.attachments.length > 0);
				}
				if (this.$store.getters.include_links_in_email) {
					this.createAttachments(scenario_settings.EMAIL.email_attachments, 'email', 'updateEmailSMSAttachments');
				}else if (this.$store.getters.include_links_in_sms) {
					this.createAttachments(scenario_settings.SMS.sms_attachment_files, 'sms', 'updateEmailSMSAttachments');
				} else if (this.$store.getters.include_links_in_teams) {
					this.createAttachments(scenario_settings.microsoft_teams.attachments, 'teams', 'updateEmailSMSAttachments');
				}	else if (this.$store.getters.include_links_in_slack) {
					this.createAttachments(scenario_settings.slack.attachments, 'slack', 'updateEmailSMSAttachments');
				}
			} else {
				if (scenario_settings.SMS) {
					let has_sms_attachments = scenario_settings.SMS && scenario_settings.SMS.sms_attachment_files &&
						scenario_settings.SMS.sms_attachment_files.length > 0 ? true : false
					this.updateIncludeLinksInSMS(has_sms_attachments);
					if (this.$store.getters.include_links_in_sms && !this.$store.getters.messages_content) {
						this.createAttachments(scenario_settings.SMS.sms_attachment_files, 'sms', 'setSMSAttachments');

					}
				}

				if (scenario_settings.EMAIL) {
					let has_email_attachments = scenario_settings.EMAIL.email_attachments &&
						scenario_settings.EMAIL.email_attachments.length > 0 ? true : false
					this.updateIncludeLinksInEmail(has_email_attachments);
					if (this.$store.getters.include_links_in_email && !this.$store.getters.messages_content) {
						this.createAttachments(scenario_settings.EMAIL.email_attachments, 'email', 'setEmailAttachments');
					}
				}
				if (scenario_settings.microsoft_teams) {
					this.updateIncludeLinksInTeams(scenario_settings.microsoft_teams.attachments && scenario_settings.microsoft_teams.attachments.length > 0);
					if (this.$store.getters.include_links_in_teams && !this.$store.getters.messages_content) {
						this.createAttachments(scenario_settings.microsoft_teams.attachments, 'teams', 'setTeamsAttachments');
					}
				}
				if (scenario_settings.slack) {
					this.updateIncludeLinksInSlack(scenario_settings.slack.attachments && scenario_settings.slack.attachments.length > 0);
					if (this.$store.getters.include_links_in_slack && !this.$store.getters.messages_content) {
						this.createAttachments(scenario_settings.slack.attachments, 'slack', 'setSlackAttachments');
					}
				}
			}
		},
		async createAttachments(attachments, attachment_type, attachments_action) {
			let file_attachments = [];
			for(let attachment of attachments) {
				let data = {};
				let obj = {};
				if (attachment_type === 'email' || attachment_type === 'teams' || attachment_type === 'slack') {
					obj = {
						"attachment_name": attachment.attachment_name,
						"attachment_key": attachment.attachment_key,
						"attachment_source": attachment.attachment_source
					};
				} else {
						obj = {
						"attachment_name": attachment.name,
						"attachment_key": attachment.key,
						"attachment_source": attachment.source
					};
				}
				if (obj.attachment_source === 'media_library') {
					try {
						data['key'] = obj.attachment_key
						const response = await MessageFormAPI.getFileObjectByKey(data);
						obj.id = attachment.id;
						obj.customer_group = attachment.customer_group;
						file_attachments.push(obj)
					}catch (error) {
						this.updateRemovedMediaLibraryFiles(obj.attachment_name)
					}
				}else {
					obj.attachment_size = attachment.attachment_size;
					file_attachments.push(obj)
				}
			}
			if(this.removed_media_library_files.length) {
				let message = {}
				message = { type: 'error', text: 'The following files ' + this.removed_media_library_files + ' were removed from the media library. Please choose new files to include in the email/sms links.' };
				this.updateMessage(message);
			}

			this.$store.dispatch(attachments_action, file_attachments);
		},
		async setNoticeboardSettings(scenario_settings) {
			if (!scenario_settings.noticeboard) {
				return;
			}
			this.updateUseNoticeboard(!!Object.keys(scenario_settings.noticeboard).length);
			this.updateNoticeboardNumber(scenario_settings.noticeboard.number);
			this.updateNoticeboardMessage(scenario_settings.noticeboard.message);

			if (scenario_settings['noticeboard']['noticeboard_tts_voice'] != undefined) {
				const people = message_forms_utils.default_people();
				if (this.customer_group_settings['default_tts_voice'] != undefined) {
					if (scenario_settings['noticeboard']['noticeboard_tts_voice'] == this.customer_group_settings['default_tts_voice']['name']) {
						this.updateDefaultNoticeboardMessageVoice(true);
					} else {
						this.updateDefaultNoticeboardMessageVoice(false);
					}

					if (this.$store.getters.default_noticeboard_message_voice == false && scenario_settings['noticeboard']['noticeboard_tts_voice'] != undefined) {
						for (let item in people) {
							if (people[item]['name'] == scenario_settings['noticeboard']['noticeboard_tts_voice']) {
								this.updateNoticeboardLanguage(people[item]);
								break;
							}
						}
					}
				} else {
					this.updateDefaultNoticeboardMessageVoice(false);
					for (let item in people) {
						if (people[item]['name'] == scenario_settings['noticeboard']['noticeboard_tts_voice']) {
							this.updateNoticeboardLanguage(people[item]);
							break;
						}
					}
				}
			}
		},
		async setContentTabSettings(scenario_settings) {
			if (!scenario_settings.global_message) {
				if (scenario_settings.EMAIL) {
					this.updateDispatchContentValue({ "dispatch_type": 'EMAIL', "text_value": scenario_settings.EMAIL.message });
				}
				if (scenario_settings.SMS) {
					this.updateDispatchContentValue({ "dispatch_type": 'SMS', "text_value": scenario_settings.SMS.message });
				}
				if (scenario_settings.VOICE) {
					this.updateDispatchContentValue({ "dispatch_type": 'VOICE', "text_value": scenario_settings.VOICE.message });
					for (let item in this.people) {
						if (this.people[item]['name'] === scenario_settings.VOICE.tts_voice) {
							this.updateTTSVoiceSelected(this.people[item]);
							break;
						}
					}
					if (scenario_settings.VOICE.message_amd_type === 'tts_amd') {
						for (let item in this.people) {
							if (this.people[item]['name'] === scenario_settings.VOICE.tts_voice) {
								this.updateTTSVoiceSelected(this.people[item]);
								break;
							}
						}
						this.updateAnsweringMachineMessageText(scenario_settings.VOICE.message_amd);
						if (this.customer_group_settings.default_amd_message && this.customer_group_settings.default_amd_message.message === scenario_settings.VOICE.message_amd) {
							this.updateUseDefaultAnsweringServicesContent(true);
						} else {
							this.updateUseDefaultAnsweringServicesContent(false);
							if (this.customer_group_settings.default_tts_voice && this.customer_group_settings.default_tts_voice.name === scenario_settings.VOICE.tts_voice) {
								this.updateUseAccountsDefaultTTSVoice(true);
							} else {
								this.updateUseAccountsDefaultTTSVoice(false);
							}
						}
					}
				}
			}
		},
		getUsersFromUrl() {
			if (!window.location.search){
				return;
			}
			const user_ids_str = window.location.search.split('=')[1]
			return user_ids_str.split(',').map(it => Number(it))
		}
	},
	watch: {
		scenario_type: async function (val) {
			await this.setRecipientTypesAndDevices();
		},
		recipient_types: {
			deep: true,
			handler() {
				let user_ids = this.getUsersFromUrl();
				if(!user_ids) {
					return;
				}
				const individuals = this.recipient_types.filter(it => it.label=='Individuals')[0];
				this.updateIncludeRecipientOptions({"index": 0, "value": individuals});
				const users = individuals.options.filter(it => user_ids.includes(it.id));
				this.updateIncludeRecipientOptionValue({"index": 0, "value": users});
			},
		},
		is_session_init_running: function(){
			if(this.is_session_init_running){
				this.$q.loading.show({})
			}else{
				this.$q.loading.hide()
			}
		}
	},
	async created() {
		if (this.current_CG.customer_group.settings == null || this.current_CG.customer_group.settings == undefined) {
			this.customer_group_settings = {};
		} else {
			this.customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
		}

		this.media_library_feature = this.current_CG.root_customer_group_permissions.includes("Media library");

		const has_noticeboard_permission = this.current_CG.root_customer_group_permissions.includes('Audio noticeboard')
		const has_noticeboard_numbers = (this.customer_group_settings.noticeboard_numbers && !!this.customer_group_settings.noticeboard_numbers.length);
		this.show_noticeboard = (has_noticeboard_permission && has_noticeboard_numbers);

		this.available_languages = message_forms_utils.build_available_languages(this.current_CG.root_customer_group_permissions);

		await this.getTranslations('response_button_label,please_press,for,or,please,please_reply,or_press,press');
		this.updatePeopleList();
		const response = await MessageFormAPI.getScenario(this.$route.params.id);
		this.setScenarioTypeNameId(response);
		await this.setRecipientTypesAndDevices();
		this.setInvocationMethodSliders(response.flags);

		let scenario_settings = JSON.parse(response.settings);
		this.setContentTabSettings(scenario_settings);

		this.setSystemPromptLanugage(scenario_settings);
		this.setDevicesAndRecipients(scenario_settings);
		this.setRecipientCriteriaSliders(scenario_settings);
		if (response.scenario_type === 'sequence') {
			this.updateSequenceTime(scenario_settings.sequence_time / 60);
		}
		this.setConferenceSettings(scenario_settings);
		this.updateMessagesContent(scenario_settings.global_message);
		if (response.scenario_type != 'conference') {
			this.updateGlobalMessage(scenario_settings.global_message_content);
		}
		if (scenario_settings.global_message) {
			if (scenario_settings.VOICE && scenario_settings.VOICE.message_type === 'tts') {
				for (let item in this.people) {
					if (this.people[item]['name'] === scenario_settings.VOICE.tts_voice) {
						this.updateTTSVoiceSelected(this.people[item]);
						break;
					}
				}
			}

			if (scenario_settings.VOICE && scenario_settings.VOICE.message_amd_type === 'tts_amd') {
				for (let item in this.people) {
					if (this.people[item]['name'] === scenario_settings.VOICE.tts_voice) {
						this.updateTTSVoiceSelected(this.people[item]);
						break;
					}
				}
			}
		}
		if (scenario_settings.quick_report_recipients) {
			this.updateSendQuickReport(true)
			let type = this.$store.getters.recipient_types.filter(item => scenario_settings.quick_report_recipients.type === item.type)
			let quick_report_recipients = []
			if (scenario_settings.quick_report_recipients.type === 'field') {
				let field_type = type.filter(item => item.id === scenario_settings.quick_report_recipients.field)
				this.updateQuickReportRecipientsType(field_type[0])
				quick_report_recipients = field_type[0].options.filter(item => scenario_settings.quick_report_recipients.values.includes(item.id))
				this.updateQuickReportRecipients(quick_report_recipients)
			} else {
				this.updateQuickReportRecipientsType(type[0])
				quick_report_recipients = type[0].options.filter(item => scenario_settings.quick_report_recipients.values.includes(item.id))
				this.updateQuickReportRecipients(quick_report_recipients)
			}
		}

		this.setPollingOptionsSettings(scenario_settings);
		this.setSmsEmailAttachments(scenario_settings);
		this.setNoticeboardSettings(scenario_settings);
		if(response.trigger) {
			this.updateTriggerScenario(true);
			this.updateTriggerTime(response.trigger.trigger_after_minutes);
			const triggered_scenario = await MessageFormAPI.getScenario(response.trigger.triggered_scenario);
			this.updateTriggeredScenario(triggered_scenario)
		}
		this.updateIsSubsetScenario(response.is_subset)
		if (scenario_settings.microsoft_teams){
			if (this.current_CG.root_customer_group_permissions.includes('Use Microsoft Teams')){
				if (!response.webhooks_teams_data.length){
					this.updateUseMicrosoftTeams(false)
					this.updateMessageContentTeams('')
				}else{
					this.updateUseMicrosoftTeams(true)
					this.updateSelectedTeamsWebhooks(response.webhooks_teams_data)
					if (!scenario_settings.global_message_content){
						this.updateMessageContentTeams(scenario_settings.microsoft_teams.message_content_teams)
					}
				}
			}else{
				this.updateUseMicrosoftTeams(false)
				this.updateMessageContentTeams('')
			}
		}
		if (scenario_settings.slack){
			if (this.current_CG.root_customer_group_permissions.includes('Use Slack')){
				if (!response.webhooks_slack_data.length){
					this.updateUseSlack(false)
					this.updateMessageContentSlack('')
				}else{
					this.updateUseSlack(true)
					this.updateSelectedSlackWebhooks(response.webhooks_slack_data)
					if (!scenario_settings.global_message_content){
						this.updateMessageContentSlack(scenario_settings.slack.message_content_slack)
					}
				}
			}else{
				this.updateUseSlack(false)
				this.updateMessageContentSlack('')
			}
		}
		if (scenario_settings.use_pwa) {
			if (this.current_CG.root_customer_group_permissions.includes('Use Flutter PWA')) {
				this.updateUsePWA(true)
			}
		}
		let auto_retry_active = scenario_settings.auto_retry &&
			scenario_settings.auto_retry_minutes ? true : false
		this.updateAutoRetry(auto_retry_active);
		this.updateAutoRetryMinutes(scenario_settings.auto_retry_minutes);
	},
	destroyed() {
		this.$store.commit('setTimeoutMessage', "");
		this.$store.commit('clearErrors');
		this.resetFields();
	},

}
</script>

<style lang="scss" scoped>

	.tabs-wrapper {
		position: sticky;
		top: 79px;
		background: #fff;
		z-index: 5;
	}

</style>

<style lang="scss">
	.data-wrapper {
		text-align: left;
		border-radius: 0.375rem;
		padding: 1rem;
		margin-bottom: 1rem;
		width: 100%;
		background: $grey-1;
		border: 1px solid $grey-4;
		@media (max-width: 768px) {
			padding: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
	.router-view-wrapper {
		padding: 1.5rem 3rem;
		background-color: #fff;
		@media (max-width: 768px) {
			padding: 0px;
		}
	}
	.padding{
		padding-left: 16px;
		padding-right: 16px;
	}

</style>
